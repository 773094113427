import React, { Component } from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import OutsideClickHandler from 'react-outside-click-handler';
import { Modal } from 'components/ModalWindow/ModalWindow';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import CloseIcon from 'static/images/icons/icon-close.inline.svg';
import PlayIcon from 'static/images/icons/play-icon.inline.svg';
import './VideoBlock.scss';


class VideoBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      play: false,
      popup: false,
    }
    this.video = null;
    this.videoPopup = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.popup !== this.state.popup) {
      if(this.state.popup) {
        this.videoPopup.play();
        this.videoPopup.addEventListener("ended", () => this.togglePopUp());
      } else {
        this.videoPopup = null;
      }
    }
  }

  pause = () => {
    this.video && this.video.pause();
    this.setState({ play: false });
  }

  play = async () => {
    this.setState({ play: true });
    this.video && await this.video.play();
  }

  togglePopUp = () => {
    this.setState({ 
      play: false,
      popup: !this.state.popup 
    });
  }
  
  render() {
    return (
      <section className="aaa-video-block">
        <ContentBox>
          <h2 className="aaa-video-block__title">
            {this.props.data.title}
          </h2>
          {this.props.data.subtitle &&
          <div
            className="aaa-video-block__subtitle"
            dangerouslySetInnerHTML={{__html: this.props.data.subtitle}}
          />}
          <div>
            <div
              className={classnames('aaa-video-block__video', {
                'play': this.state.play,
              })}
              onClick={() => this.togglePopUp()}
              onMouseEnter={async () => await this.play()}
              onMouseLeave={() => this.pause()}
            >
              <video loop muted preload="metadata" ref={e => this.video = e}>
                <source src={this.props.data.video.publicURL} type="video/mp4" />
              </video>
              <div className="aaa-video-block__poster">
                <Img 
                  className="aaa-video-block__poster"
                  fluid={this.props.data.poster.childImageSharp.fluid}
                />
              </div>
              <div className="aaa-video-block__play">
                <PlayIcon className="aaa-video-block__play-icon" />
              </div>
            </div>
          </div>
        </ContentBox>
        { this.state.popup &&
          <Modal>
            <div className="aaa-video-popup">
              <OutsideClickHandler onOutsideClick={() => this.togglePopUp()}>
                <video 
                  controls
                  preload="metadata"
                  ref={e => this.videoPopup = e}
                >
                  <source src={this.props.data.video.publicURL} type="video/mp4" />
                </video>
              </OutsideClickHandler>
              <div className="aaa-video-popup__close">
                <CloseIcon className='aaa-video-popup__close-icon' />
              </div>
            </div>
          </Modal>
        }
      </section>
    );
  }
}

export default VideoBlock;