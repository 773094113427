import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Link, { ArrowLink } from 'components/Shared/Link/Link';
import ZipContext from 'components/Shared/ZipContext';
import IconCheck from 'static/images/icons/check.inline.svg';
import IconClose from 'static/images/icons/icon-close.inline.svg';
import './ComparisonTable.scss';


const FeatureIcon = ({ type }) => {
  return type === 'Yes' ? <IconCheck className="check" /> : <IconClose className="cross" />;
}

const FeatureValue = ({ value }) => {
  if(Array.isArray(value)) {
    return (
      <Fragment>
        {value.map( (v, i) => <div key={i}>{v}</div>)}
      </Fragment>
    );
  } else {
    return value
  }
}

class ComparisonTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: this.getTableRows()
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.table.length !== this.props.table.length) {
      this.setState({
        rows: this.getTableRows()
      });
    }
  }

  getTableRows() {
    const rows = {};
    this.props.table.forEach( (e, i) => {
      e.features.forEach( feature => {
        if(rows[feature.name] === undefined) {
          rows[feature.name] = Array(this.props.table.length);
        }
        rows[feature.name][i] = feature.values ? feature.values : feature.value;
      });
    });
    return rows;
  }

  render() {
    const features = this.state.rows;
    return (
      <section className="aaa-comparison-table">
        <ContentBox>
          <h2 className="aaa-comparison-table__title">
            {this.props.title}
          </h2>
          <div className="aaa-comparison-table__table-box">
            <table cellSpacing={0}>
              <thead>
                <tr>
                  <th></th>
                  {this.props.table.map( (product, i) => {
                    const linked = product.productId !== this.props.productId && product.link;
                    let image = <Img className="aaa-comparison-table__product-image" fluid={product.image.childImageSharp.fluid} />;
                    let title = <div className="aaa-comparison-table__product-title">{product.title}</div>;
                    if (linked) {
                      image = <Link to={product.link.url}>{image}</Link>;
                      title = <Link to={product.link.url}>{title}</Link>;
                    }
                    return (
                      <th key={`th_${i}`} className={classnames({"aaa-comparison-table__product-container": linked})}>
                        {image}
                        {title}
                        {linked &&
                          <ArrowLink 
                            className="aaa-comparison-table__product-link"
                            to={product.link.url}>
                            {product.link.text}
                          </ArrowLink> 
                        }
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(features).map( (feature, i) => (
                  <tr key={`feature_${i}`}>
                    <td className="aaa-comparison-table__feature-title">
                      {feature}
                    </td>
                    {features[feature].map( (val, idx) => (
                      <td
                        className="aaa-comparison-table__feature-value"
                        key={`${i}_${idx}`}
                      >
                        {val === 'Yes' || val === 'No' ? <FeatureIcon type={val} /> : <FeatureValue value={val}/>}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ContentBox>
      </section>
    );
  }
}

const ComparisonTableWithContext = ({data, productId}) => (
  <ZipContext.Consumer>
    {zipContext => {
      let {nonServiceExclude, table, title} = data;
      const i = table.findIndex(p => p.productId === productId);
      if(i > 0) {
        table.unshift(table[i]);
        table.splice(i+1, 1);
      }
      if(!(zipContext.fullAccess || nonServiceExclude.includes(productId))) {
        table = table.filter(item => !nonServiceExclude.includes(item.productId));
      }
      return <ComparisonTable productId={productId} table={table} title={title} />
    }}
  </ZipContext.Consumer>
);

export default ComparisonTableWithContext;
