import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import CTAForm from 'components/CTAForm';
import InfoBlock, { IconsBlock } from 'components/InfoBlock';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import { CallUsBlock } from 'components/ProductKits';
import ProductDetails, { 
  ComparisonTableCameras, DiagramBlock, TwoColumnsBlock, VideoBlock 
} from 'components/ProductDetails';
import ScrollToTopButton from 'components/ScrollToTopButton';

const propTypes = {
  data: PropTypes.object.isRequired,
};

class ProductDetailsTemplate extends React.Component {

  comparisonTable = type => {
    if(type === 'cameras') {
      return <ComparisonTableCameras productId={this.props.data.productDetailsYaml.productId} />;
    }
    return null;
  }

  render() {
    const { data, location } = this.props;
    return (
      <Layout location={location} type="standard">
        <SEO
          title={data.productDetailsYaml.title}
          meta={data.productDetailsYaml.metaTags}
          titleTemplate="%s"
        />
        <ProductDetails data={data.productDetailsYaml.ecommerceBlock} />
        {data.productDetailsYaml.iconsBlock && <IconsBlock data={data.productDetailsYaml.iconsBlock} nobg={true} />}
        {data.productDetailsYaml.videoBlock && <VideoBlock data={data.productDetailsYaml.videoBlock} />}
        {data.productDetailsYaml.twoColumnsBlock && <TwoColumnsBlock data={data.productDetailsYaml.twoColumnsBlock} />}
        {data.productDetailsYaml.bigInfoCards && data.productDetailsYaml.bigInfoCards.map((card, i) => (
          <InfoCardBig key={i} data={card} />
        ))}
        {data.productDetailsYaml.callUs && <CallUsBlock data={data.productDetailsYaml.callUs} />}
        {data.productDetailsYaml.infoBlockExtra && <InfoBlock data={data.productDetailsYaml.infoBlockExtra} />}
        {data.productDetailsYaml.diagramBlock && data.productDetailsYaml.diagramBlock.map((d, i) => (
          <DiagramBlock data={d} key={i} />
        ))}
        {this.comparisonTable(data.productDetailsYaml.comparisonTable)}
        {data.productDetailsYaml.separator1 == 'Yes' && <hr />}
        {data.productDetailsYaml.infoBlock && <InfoBlock data={data.productDetailsYaml.infoBlock} />}
        {data.productDetailsYaml.separator2 == 'Yes' && <hr />}
        <CTAForm additionalFields={{product_kit: data.productDetailsYaml.ecommerceBlock.title}} />
        <ZipGateContainer location={location}/>
        <ScrollToTopButton />
      </Layout>
    );
  }
}
ProductDetailsTemplate.propTypes = propTypes;

export default ProductDetailsTemplate;

export const pageQuery = graphql`
  query ProductDetailsQuery($id: String!) {
    productDetailsYaml(id: {eq: $id}) {
      id
      productId
      path
      title
      ecommerceBlock {
        images {
          image {
            childImageSharp {
              fluid(maxWidth: 1716, maxHeight: 1212) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imagePosition
        }
        title
        price
        description
        colors {
          title
          list {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 60, maxHeight: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      iconsBlock {
        icons {
          text
          icon
        }
      }
      videoBlock {
        title
        poster {
          childImageSharp {
            fluid(maxWidth: 2360, maxHeight: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        video {
          publicURL
        }
      }
      twoColumnsBlock {
        color
        title
        cards {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 535) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      bigInfoCards {
        color
        type
        title
        description
        note
        image {
          childImageSharp {
            fluid(maxWidth: 891) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: 891) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      infoBlockExtra {
        title
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      callUs
      diagramBlock {
        type
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 891) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        specs {
          title
          text
          link {
            text
            url
          }
        }
        separator
      }
      comparisonTable
      infoBlock {
        title
        infoCards {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link {
            text
            url
          }
        }
      }
      separator1
      separator2
    }
  }
`
