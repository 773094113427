import React from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemState
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import { ArrowLink } from 'components/Shared/Link/Link';
import IconArrowDown from 'static/images/icons/chevron-down.inline.svg';
import './DiagramBlock.scss';

const DiagramBlock = ({ data }) => (
  <section className={classnames("aaa-diagramblock", {
    separator: data.separator == "Yes"
  })}>
    <ContentBox>
      <div className={classnames("row", {
        reverse: data.type === "text-first"
      })}>
        <div className={classnames("col-6", "aaa-diagramblock__image-col", {
          "offset-lg-1": data.type === "text-first"
        })}>
          <Img fluid={data.image.childImageSharp.fluid} />
        </div>
        <div className={classnames("col-12", "col-lg-5", {
          "offset-lg-1": data.type === "image-first"
        })}>
          <h2 
            className={classnames("aaa-diagramblock__title", {
              "has-description": data.description
            })}
            dangerouslySetInnerHTML={{__html: data.title}}
          />
          {data.description && (
            <div
              className="aaa-diagramblock__description"
              dangerouslySetInnerHTML={{__html: data.description}}
            />
          )}
          <div className="aaa-diagramblock__image-box">
            <Img fluid={data.image.childImageSharp.fluid} />
          </div>
          <Accordion 
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
          >
            {data.specs.map( (item, i) => (
              <AccordionItem collapsed="false" key={i}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="aaa-diagramblock__spec-title">{item.title}</span>
                    <IconArrowDown className="aaa-diagramblock__spec-caret" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemState>
                  {({ expanded }) => (
                    <div className="accordion__panel">
                      <AnimateHeight
                        duration={300}
                        height={expanded ? 'auto' : 0}
                      >
                        <div 
                          className="aaa-diagramblock__text"
                          dangerouslySetInnerHTML={{
                            __html: item.text
                          }}
                        />
                        {item.link && <ArrowLink to={item.link.url}>{item.link.text}</ArrowLink>}
                        <SeparatorWithGradient />
                      </AnimateHeight>
                    </div>
                  )}
                </AccordionItemState>
              </AccordionItem>
            ))}
            </Accordion>
        </div>
      </div>
    </ContentBox>
  </section>
);

export default DiagramBlock;
