import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ComparisonTable from './ComparisonTable';

const ComparisonTableCameras = (props) => (
  <StaticQuery
    query={graphql`
      query {
        comparisonTableCamerasYaml {
          title
          table {
            productId
            image {
              childImageSharp {
                fluid(maxWidth: 220, maxHeight: 176) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  height
                  width
                }
              }
            }
            title
            link {
              url
              text
            }
            features {
              name
              value
              values
            }
          }
          nonServiceExclude
        }
      }
    `}
    render={data => <ComparisonTable data={data.comparisonTableCamerasYaml} {...props} />}
  />
);

export default ComparisonTableCameras;
