import React from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './TwoColumnsBlock.scss';

const TwoColumnsBlock = ({ data }) => (
  <div className={classnames("aaa-twocolumnsblock", {
    white: data.color === 'white',
    grey: data.color === 'grey' 
  })}>
    <ContentBox>
      <h2 className="aaa-twocolumnsblock__title">{data.title}</h2>
      {/* <div className="aaa-twocolumnsinfo__cards-container"> */}
        <div className="row justify-content-center">
          {data.cards.map((item, i) => (
            <div
              key={i}
              className="col-12 col-md-6 aaa-twocolumnsblock__card"
            >
              <div className="aaa-twocolumnsblock__card-box">
                <Img
                  className="aaa-twocolumnsblock__card-image"
                  fluid={item.image.childImageSharp.fluid}
                />
                <div className="aaa-twocolumnsblock__card-title">{item.title}</div>
              </div>
            </div>
          ))}
        </div>
      {/* </div> */}
    </ContentBox>
  </div>
);

export default TwoColumnsBlock;
