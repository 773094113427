import React from 'react';
import Img from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import { withHidePopUpButton } from 'components/CTAForm';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { CTAButton } from 'components/Shared/Button/Button';
import Link from 'components/Shared/Link/Link';
import ArrowLeft from 'static/images/icons/arrow-left.inline.svg';
import IconArrowLeft from 'static/images/icons/chevron-left.inline.svg';
import IconArrowRight from 'static/images/icons/chevron-right.inline.svg';
import './EcommerceBlock.scss';


export const EcommerceBlock = ({ data }) => (
  <section className="aaa-ecommerce">
    <ContentBox>
      <div className="aaa-ecommerce__content-box">
        <Link className="aaa-ecommerce__back-link" to="/products">
          <ArrowLeft className="aaa-ecommerce__back-link-icon" />
          Back to Products
        </Link>
        <h1 className="aaa-ecommerce__title">{data.title}</h1>
        <div className="aaa-ecommerce__price">${data.price}</div>
        <div className="aaa-ecommerce__image-box">
          <Carousel
            autoPlay={data.images.length > 1}
            className="aaa-ecommerce__carousel"
            showThumbs={false}
            infiniteLoop
            showStatus={false}
            showIndicators={data.images.length > 1}
            swipeable={data.images.length > 1}
            swipeScrollTolerance={20}
            verticalSwipe="natural"
            selectedItem={0}
            centerMode
            centerSlidePercentage={100.5}
            renderArrowNext={(clickHandler) => (
              <button
                disabled={data.images.length < 2}
                className="control-arrow control-next"
                aria-label="next slide/item"
                onClick={clickHandler}
              >
                <IconArrowRight />
                <IconArrowRight className="shadow" />
              </button>
            )}
            renderArrowPrev={(clickHandler) => (
              <button
                disabled={data.images.length < 2}
                className="control-arrow control-prev"
                aria-label="previous slide/item"
                onClick={clickHandler}
              >
                <IconArrowLeft />
                <IconArrowLeft className="shadow" />
              </button>
            )}
          >
            {data.images.map((item, i) => (
              <Img
                className="aaa-ecommerce__image"
                fluid={item.image.childImageSharp.fluid}
                imgStyle={
                  item.imagePosition ? { objectPosition: item.imagePosition } : {}
                }
                key={i}
              />
            ))}
          </Carousel>
        </div>
        <div
          className="aaa-ecommerce__description"
          dangerouslySetInnerHTML={{__html: data.description}}
        />
        { data.colors && 
          <div className="aaa-ecommerce__colors">
            <div>{data.colors.title}</div>
            <ul className="aaa-ecommerce__colors-list">
              {data.colors.list.map((color, i) => (
                <li className="aaa-ecommerce__color" key={i}>
                  <Img
                    className="aaa-ecommerce__color-icon"
                    fluid={color.image.childImageSharp.fluid}
                    title={color.title}
                  />
                  <span>{color.title}</span>
                </li>
              ))}
            </ul>
          </div>
        }
        <div className="aaa-ecommerce__quantity"></div>
        <CTAButton>Get a Free Quote</CTAButton>
      </div>
    </ContentBox>
  </section>
);

export default withHidePopUpButton(EcommerceBlock, { top: 100 });
